@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
/*
font-family: 'Lato', sans-serif;
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

*,
*:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-size: 14px;
}
